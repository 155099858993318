import "core-js/modules/es.array.push.js";
import indexDataChart from "@/components/indexDataChart.vue";
import { getIndexData } from "../api/api";
export default {
  name: "index",
  components: {
    indexDataChart
  },
  data() {
    return {
      indexInfo: []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getIndexData().then(res => {
        this.indexInfo = res.data;
        this.$refs.chartOptionData.initChart(this.indexInfo.monthData, 'axisOptions'); //近一年的数据统计
        if (this.indexInfo.bazeList) {
          let mapBazeData = [];
          for (let i in this.indexInfo.bazeList) {
            let name = this.$root.getCityCode(this.indexInfo.bazeList[i].city, 'city');
            mapBazeData.push({
              name: name,
              value: this.indexInfo.bazeList[i].value
            });
          }
          this.$refs.chartMap1OptionData.initChart(mapBazeData, 'mapOption', ['orange', 'yellow']);
        }
        if (this.indexInfo.fieldtorList) {
          let mapFieldData = [];
          for (let i in this.indexInfo.fieldtorList) {
            let name = this.$root.getCityCode(this.indexInfo.fieldtorList[i].city, 'city');
            mapFieldData.push({
              name: name,
              value: this.indexInfo.fieldtorList[i].value
            });
          }
          this.$refs.chartMap2OptionData.initChart(mapFieldData, 'mapOption', ['#7063F7', '#F3EBFF']);
        }
      });
    }
  }
};